import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import React from 'react';
import { ArticleCard } from '../../components/article_card/article_card';
import { ContentWrapper } from '../../components/content_wrapper/content_wrapper';
import { SEO } from '../../components/seo';
import { GatsbyPageProps } from '../../gatsby-types';
import { useIsMobile } from '../../hooks/useIsMobile';
import Layout from '../../layouts/layout';
import { getImgixPath } from '../../lib/imgix';
import { PrismicArticle } from '../../models/prismic_article';
import { PrismicContributor } from '../../models/prismic_contributor';
import * as styles from './contributor.module.less';

export const query = graphql`
  query($uid: String, $tag: [String!]) {
    allPrismicContributor(filter: { uid: { eq: $uid } }) {
      nodes {
        uid
        data {
          name {
            raw
          }
          image {
            alt
            dimensions {
              height
              width
            }
            url
          }
        }
      }
    }
    allPrismicArticle(filter: { tags: { in: $tag } }) {
      nodes {
        ...PrismicArticleFragment
      }
    }
  }
`;

const ContributorPage = (props: GatsbyPageProps) => {
  const isMobile = useIsMobile();
  const author = props.data.allPrismicContributor.nodes[0] as PrismicContributor;
  if (!author) {
    console.log('Contributor not found');
    return null;
  }

  const name = RichText.asText(author.data.name.raw);

  const articles = props.data.allPrismicArticle.nodes as PrismicArticle[];

  return (
    <Layout>
      <SEO title={name} />
      <ContentWrapper>
        <article>
          <img src={getImgixPath(author.data.image.url, { w: isMobile ? 600 : 1330 })} alt={name} />
          <h1>{name}</h1>

          <h2>Articles</h2>
          <ol className={styles.articles}>
            {articles.map((article) => (
              <li className={styles.article} key={article.uid}>
                <ArticleCard article={article} />
              </li>
            ))}
          </ol>
        </article>
      </ContentWrapper>
    </Layout>
  );
};

export default ContributorPage;
